/* --------------------------------
   buttons
-------------------------------- */

.custom-btn
{
	position: relative;
	display: inline-block;
	vertical-align: middle;
	min-width: 135px;
	// min-height: 46px;
	padding: 15px 10px 13px;
	background-color: transparent;
	border: {
		width: 3px;
		style: solid;
		color: $white;
	};
	color: $white;
	line-height: 1;
	font: {
		size: rem-calc(12px);
		weight: 600;
		family: $secondary-fontFamily;
	};
	text: {
		align: center;
		transform: uppercase;
		decoration: none;
	};
	letter-spacing: 0;
	cursor: pointer;
	appearance: none;
	user-select: none;
	box-shadow: none;
	outline: none;
	@include border-radius(5px);
	@include transition(
		background-color 0.3s ease-in-out,
		border-color 0.3s ease-in-out,
		color 0.3s ease-in-out
	);

	&:hover,
	&:focus,
	&:active
	{
		background-color: $white;
		color: #ffffff;
	}

	&.primary
	{
		border-color: $secondary-color;
		color: #ffffff;

		&:hover,
		&:focus,
		&:active
		{
			background-color: $secondary-color;
		}
	}

	&.big
	{
		min-height: 56px;
		min-width: 167px;
		padding: 20px 10px 18px;
	}

	&.long { width: 100% ;}
}